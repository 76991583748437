const Headings ={
    "Networking":"The power of networking",
    "Mapping":"Mapping entrepreneurs",
    "Crisis":"Entrepreneurs & Crisis",
    "Shelf":"Winning Shelf Space",
    "Waluigi":"Waluigi Test! aasdfas s",
    "Dutchman":"Dutchman Test!"
 }

 export default Headings;
