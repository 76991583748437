import './App.css';
import MainClass from './mainclass.component'

function App() {
  return (
    <MainClass/>
  );
}

export default App;
